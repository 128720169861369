<template>
    <div class="grid">  
		<div class="col-12"> 
            
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>
                            
                             {{ module_name }}<br />
                             <span style="font-size:medium" v-if="duration">Duration : {{ duration }}</span>
                        </h3> 
                    </template>
</Toolbar>

<Toolbar class="pl-0 pr-0">
    <template v-slot:start>
                        <Button  v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        
					</template>
</Toolbar>
<Panel header="Filters" v-if="openFilterBox">
    <div class="grid" style="font-size:16px;">
        <div class="col-12 lg:col-6 sm:col-12 mb-2">
            <div class="grid">
                <div class="col-12 lg:col-3 sm:col-12 mb-2">
                    <label>Status</label>
                    <Dropdown v-model="getStatus" :options="status" optionLabel="label" optionValue="value"
                        class="full-width" />
                </div>
                <div class="col-12 lg:col-3 sm:col-12 mb-2">
                    <label>Leave From Date</label>
                    <Calendar id="icon" v-model="startDate" :showIcon="true" class="full-width" placeholder="From Date"
                        dateFormat="M-dd-yy" />
                </div>
                <div class="col-12 lg:col-3 sm:col-12 mb-2">
                    <label>Leave To Date</label>
                    <Calendar id="icon" v-model="endDate" :showIcon="true" class="full-width" placeholder="To Date"
                        dateFormat="M-dd-yy" />
                </div>
                <div class="col-12 lg:col-2 sm:col-12 mb-2 mt-4">
                    <Button v-tooltip.top="'Filter'" :label="showBtnLabels ? 'Filter' : 'Filter'" icon="pi pi-filter"
                        :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm ' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                        class="p-button-raised p-button-success mr-2 full-width" @click="filterResult" />
                </div>
            </div>
        </div>
    </div>
</Panel>

<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true"
    sortMode="multiple" class="p-datatable-gridlines p-datatable-sm" :rows="10" dataKey="app_id" :rowHover="true"
    v-model:filters="filters" :loading="loading" :filters="filters" responsiveLayout="scroll"
    :globalFilterFields="['app_id', 'class', 'student_id', 'name', 'email', 'mobile_1', 'leave_type', 'father_name', 'fath_email', 'father_mobile_no', 'leave_start_date', 'leave_end_date', 'in_date', 'out_date', 'date_added', 'cnic']"
    :rowsPerPageOptions="[10, 20, 50, 100]"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords"
    selectionMode="multiple" :scrollable="true" scrollHeight="400px">


    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" ></i>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template>

    <template #empty>
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
    <template #loading>
                        Loading data. Please wait.
                    </template>

    <!-- <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column> -->
    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
        :exportable="col.field == 'action' ? false : true" :sortable="col.field == 'action' ? false : true"
        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction' : ''"
        :style="col.field !== 'action' ? 'min-width:20rem;flex-grow:1; flex-basis:160px;' : 'width:5rem;flex-grow:1; flex-basis:160px;'">

        <template #body="{ data }">
                            <span v-if="col.field == 'app_id'">
                                <b>Application ID:</b> {{ data.app_id }}<br />
                                <b>Date Applied:</b> {{ data.date_added }}
                            </span>
                            <img  v-if="col.field == 'name' && data.profile_pic" :alt="data.name" :src="studentFiles + 'students/' + data.std_id + '/' + data.profile_pic" class="tableProfileImg mr-2"  style="height:50px; width:50px;"/>
                            <img  v-if="col.field == 'name' && !data.profile_pic" :alt="data.name" :src="filesUrl + 'defaults/default.png'" class="tableProfileImg mr-2" style="height:50px; width:50px;" />
                            
                            <span v-if="col.field == 'name'" @click="selectedRecordId = data.app_id; getClickedRecord('view');">
                                <b>{{ data.name }} - {{ data.student_id }} </b> <br />
                                {{ data.email }}<br />
                                {{ data.mobile_1 }}
                            </span> 
                            <span v-if="col.field == 'class'">
                                <b>Class: </b> {{ data.class }}<br />
                                <b>Room No: </b>{{ data.room_no ? data.room_no : 'N/A' }}<br />
                                <b>Roll No: </b>{{ data.system_rollno_prefix }}-{{ data.system_rollno }}
                            </span>
                            <span v-if="col.field == 'leave_start_date'">                               
                                 <b>Type: </b> <Tag :class="'mr-2 status-Active'" :severity="'success'" :value="data.leave_type"></Tag><br />
                                 <b>From: </b>{{ (data.leave_start_date) }}<br />
                                 <b>To: </b>   {{ data.leave_end_date }}                             
                            </span>
                            <span v-if="col.field == 'leave_status'">
                                <Tag v-if="data.leave_status == 0" :class="'mr-2 status-Active'" icon="pi pi-info-circle" :severity="'warning'" :value="'Pending'"></Tag>
                                <Tag v-if="data.leave_status == 1" :class="'mr-2 status-Active'" icon="pi pi-check-circle" :severity="'success'" :value="'Approved'"></Tag>
                                <Tag v-if="data.leave_status == 2" :class="'mr-2 status-Blocked'" icon="pi pi-times-circle" :severity="'danger'" :value="'Rejected'"></Tag>
                                <br />
                                <b>Last Modified: </b>{{ data.date_modification }}
                            </span>
                            
                            <span v-if="col.field == 'out_date'">
                                <b>OUT: </b>{{ data.out_date ? data.out_date : 'N/A' }}
                                <br />
                                <b>IN: </b>{{ data.in_date ? (data.in_date) : 'N/A' }}
                            </span>
                            <!-- <span v-if="col.field == 'in_date'">{{ data.in_date ? (data.in_date):null }}</span> -->
                            <span v-if="col.field == 'leave_application'">{{ shortStr(htmlparser_data(data.leave_application)) }}</span>

                            <!-- <span v-if="col.field == 'leave_end_date'">{{ (data.leave_end_date) }}</span> -->
                            <!-- <span v-if="col.field == 'leave_type'">{{ data.leave_type }}</span> -->
                            <!-- <span v-if="col.field == 'file_name'">
                                <Tag v-if="data.file_name" @click="downloadFile(data.file_name)"  :class="'mr-2 status-Active'" icon="pi pi-eye" :severity="'success'" :value="'View File'"></Tag>
                                <Tag v-else :class="'mr-2 status-Blocked'" icon="pi pi-times" :severity="'danger'" :value="'No File'"></Tag>
                            </span> -->
                            

                            <!-- <span v-if="col.field == 'email'">{{ data.email }}</span> -->
                            <span v-if="col.field == 'cnic'">
                                <b>CNIC:</b>    {{ data.cnic }}<br />
                                <b>Gender:</b>    {{ data.gender }}<br />
                            </span>
                            <!-- <span v-if="col.field == 'gender'">{{ data.gender }}</span> -->
                            <!-- <span v-if="col.field == 'mobile_1'">{{ data.mobile_1 }}</span> -->
                            <span v-if="col.field == 'father_name'">
                                <b>Name: </b>{{ data.father_name }}<br />
                                <b>Email: </b>{{ data.fath_email ? data.fath_email : data.guardian_email }}<br />
                                <b>Mobile: </b>{{ data.father_mobile_no ? data.father_mobile_no : 'N/A' }}
                            </span>
                            <!-- <span v-if="col.field == 'date_added'">{{ (data.date_added) }}</span> -->

                            <span v-if="col.field == 'status'"> 
                                <Tag :class="data.status == 'Active' ? 'mr-2 status-Active' : 'mr-2 status-Blocked'" icon="pi pi-check" :severity="data.status == 'Active' ? 'success' : 'danger'" :value="data.status"></Tag>
                            </span>
                            <span v-if="col.field == 'action'">
                                <div :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :recordId="data.app_id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div> 
                            </span>
                        </template>
        <template #filter="{ filterModel }">
                            <span v-if="col.field == 'app_id'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Application Id"/>
                            </span>
                            <!-- <span v-if="col.field == 'name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Name"/>
                            </span> 
                            <span v-if="col.field == 'class'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by class"/>
                            </span> 
                            <span v-if="col.field == 'leave_start_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by From Date"/>
                            </span> 
                            <span v-if="col.field == 'leave_end_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by To Date"/>
                            </span> 
                            <span v-if="col.field == 'out_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Out Date"/>
                            </span> 
                            <span v-if="col.field == 'in_date'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by In Date"/>
                            </span>   
                            <span v-if="col.field == 'date_added'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Date Added"/>
                            </span>
                            <span v-if="col.field == 'father_name'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by father_name"/>
                            </span> 
                            <span v-if="col.field == 'gender'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by gender"/>
                            </span> 
                            <span v-if="col.field == 'cnic'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by cnic"/>
                            </span> 
                            <span v-if="col.field == 'leave_type'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by leave_type"/>
                            </span> -->
                           
                        </template>
    </Column>
</DataTable>
</div>
</div>
<Toast />

<StudentForm v-on:formClose="closeForm" v-if="showForm" :recordId="selectedRecordId" :showForm="true" />
<LeaveApplicationView v-on:formClose="closeForm" v-if="showView" :recordId="selectedRecordId" :showView="true" />
</div>
</template>
<script>
import StudentForm from '../../components/Students/StudentForm.vue';
import LeaveApplicationView from '../../components/LeaveApplications/LeaveApplicationView.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import Panel from 'primevue/panel';
export default {
    data() {
        return {
            module_name: 'Expected Arrivals',
            actions: [],
            showHelp: false,
            isSaving: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            showView: false,
            selectedRecordId: 0,
            saveAndClose: false,

            openFilterBox: false,
            //by default get pending applications
            getStatus: 0,
            startDate: this.moment(new Date()).subtract(1, 'week').format('MMM-DD-YYYY'),
            endDate: this.moment(new Date()).format('MMM-DD-YYYY'),

            status: [
                { label: 'Pending', value: 0 },
                { label: 'Approved', value: 1 },
                { label: 'Rejected', value: 2 },
            ],
            duration: '',
        }
    },
    components: {
        StudentForm,
        LeaveApplicationView,
        Panel,
    },
    created() {
        this.actions.push({
            label: 'View',
            icon: 'pi pi-eye',
            command: () => {
                this.getClickedRecord('view');
            }
        });
        this.initFilters();
        this.columns = [
            { field: 'action', header: 'Action' },
            { field: 'app_id', header: 'Application Info' },
            { field: 'name', header: 'Name' },
            { field: 'class', header: 'Academic Info' },
            { field: 'leave_start_date', header: 'Leave Info' },
            { field: 'leave_status', header: 'Status' },
            { field: 'out_date', header: 'Activity' },
            { field: 'leave_application', header: 'Description' },
            { field: 'cnic', header: 'Personal Info' },
            { field: 'father_name', header: 'Father Name' },


        ];
        this.selectedColumns = this.columns;
    },
    computed: {
    },
    mounted() {
        this.emitter.on("Reload", (status) => {
            this.getRecords();
        });
        this.getRecords();
    },
    methods: {

        getClickedRecord(action) {
            if (action == 'view') {
                this.showView = true;
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.showForm = true;
        },
        closeForm() {
            if (!this.selectedRecordId) {
                this.getRecords();
            }
            this.showForm = false;
            this.showView = false;
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllHosteliseExpectedStudentsArrivals', { status: this.getStatus })
                .then((response) => {
                    this.records = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {
                })
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        onToggle(value) {
            this.selectedColumns = this.columns.filter(col => value.includes(col));
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                // 'app_id': { value: null, matchMode: FilterMatchMode.CONTAINS}, 
                // 'name': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'class': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'leave_start_date': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'leave_end_date': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'date_added':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'out_date':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'in_date':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'leave_type':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'gender':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'father_name':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
                // 'cnic':{operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 

            }
        },
        clearFilter() {
            this.initFilters();
            this.getStatus = 0;
            this.duration = '';
            this.openFilterBox = false;
            this.getRecords();
        },
        isRTL() {
            return this.$appState.RTL;
        },
        filterResult() {
            this.duration = this.moment(this.startDate).format('MMM-DD-YYYY') + ' -to- ' + this.moment(this.endDate).format('MMM-DD-YYYY');
            var sDate = this.dateFormate(this.startDate) + ' 00:00:00';
            var eDate = this.dateFormate(this.endDate) + ' 23:59:59';
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllHosteliseExpectedStudentsArrivals', { status: this.getStatus, startDate: sDate, endDate: eDate })
                .then((response) => {
                    this.records = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                }).finally(() => {
                })
        },

        dateFormate(date) {
            if (date)
                return this.moment(date).format('YYYY-M-D');
        }


    },
    watch: {
    }
}
</script>
